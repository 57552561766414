<template>
  <!-- 设备绑定页面 -->
  <div>
    <!-- 头部开始 -->
    <el-row class="pur-top">
      <el-col :span="3">
        <span class="pur-size">{{ purTitle }}</span></el-col>
        <el-col :span="21" class="pur-right">
            <el-input
            @keyup.enter.native="search"
            size="small"
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="purSearch">
            </el-input>
        </el-col>
    </el-row>
    <!-- 头部结束 -->
    <!-- 内容开始 -->
    <div class="pur-nav"
    v-loading="loading"
    element-loading-text = "加载中...">
      <!-- 表格 -->
      <div class="pur-table">
        <el-table
          height="100%"
          border
          :data="tableData"
          :header-cell-style="{ background: '#F6F6F6', color: '#333' }"
          style="width: 100%;">
            <el-table-column prop="id" v-if="show">
            </el-table-column>
            <el-table-column label="终端型号" :show-overflow-tooltip="true" prop="terminalModel">
            </el-table-column>
            <el-table-column label="终端SN码" :show-overflow-tooltip="true" prop="terminalId">
            </el-table-column>
            <el-table-column label="设备类型" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span v-if="scope.row.equipmentType == 1">定位设备</span>
                  <span v-if="scope.row.equipmentType == 2">视频设备</span>
              </template>
            </el-table-column>
            <el-table-column label="备注车牌" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span v-if="scope.row.licensePlateRemark">{{scope.row.licensePlateRemark}}</span>
                  <span v-else>---</span>
              </template>
            </el-table-column>
            <el-table-column label="设备到期时间" :show-overflow-tooltip="true" prop="equipmentExpirationTime">
            </el-table-column>
            <el-table-column label="车牌号" :show-overflow-tooltip="true">
              <template slot-scope="scope">
                  <span v-if="scope.row.licensePlate">{{scope.row.licensePlate}}</span>
                  <span v-else>---</span>
              </template>
            </el-table-column>
            <el-table-column
              label="操作" width="180" fixed="right">
              <template slot-scope="scope">
                <el-button @click="Unbind(scope.row)" size="mini" v-if="scope.row.licensePlate">取消绑定</el-button>
                <el-button @click="bind(scope.row)" size="mini" v-else>绑定车辆</el-button>
              </template>
            </el-table-column>
          <!-- 暂无数据 -->
          <div slot="empty" class="dataAvailable">
              <div class="dataAva">
                <img src="../assets/zanwushuju.png" alt="">
                <p>暂无设备，请联系管理员</p>
              </div>
          </div>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pur-pages">
        <el-pagination
        background
        :page-sizes="[10, 30, 50, 100, 200]"
        :page-size.sync="size"
        :pager-count="5"
        :current-page.sync="current"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @current-change='getpurcon'
        @size-change='getpurcon'>
        </el-pagination>
      </div>
      <!-- 车辆绑定弹窗 -->
      <el-dialog
        title="绑定车辆"
        :closeOnClickModal="false"
        :visible.sync="bindShow"
        width="35%"
        :before-close="bindClose">
          <el-form style="width:95%;" :model="bindData" :rules="bindrules" ref="bindData" size="small" label-width="100px">
            <el-form-item label="车辆绑定">
              <el-select v-model="bindData.newcarId" clearable filterable placeholder="请选择车辆信息" style="width:100%;">
                  <el-option
                  v-for="item in bindoptions"
                  :key="item.id"
                  :label="item.licensePlate + ' ' + item.driverName + ' ' + item.phone"
                  :value="item.id + ',' + item.licensePlate">
                  </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="bindsubmitm('bindData')" style="float:right;margin:5% 0 0 2%;">确定</el-button>
              <el-button @click="bindresetForm" style="float:right;margin:5% 0 0 2%;">取消</el-button>
            </el-form-item>
          </el-form>
      </el-dialog>
    </div>
    <!-- 内容结束 -->
  </div>
</template>
<script>
export default {
    data() {
        return {
          loading:false,
          current:1,              // 当前页数
          size:30,                // 显示条数
          total:0,                // 总条数
          delmul:[],              // 批量删除
          show:false,
          purTitle: "",           // 标题
          purSearch: "",          // 搜索      
          tableData: [],          // 表格数据  
          // 车辆绑定
          bindShow:false,
          bindData:{},
          bindrules: {},
          bindoptions: [],
        };
    },
    created() {},
    mounted(){
      this.purTitle = this.$route.meta.title;
      this.getpurcon();
    },
    methods: {
      // 表格数据
      getpurcon(){
        this.loading = true;
        // 传参
        var listByPageData = {
          searchStr:this.purSearch,
          page:this.current,
          size:this.size
        }
        // 渲染表格
        this.api.Terminal.all(listByPageData)
        .then(res=>{
          this.loading = false;
          if(res.data.code == 200){
              this.tableData = res.data.data.dataList;
              this.total = res.data.data.totalCount;
          }
        })
      },
      // 搜索功能
      search() {
        this.tableData = [];
        this.getpurcon();
        this.current = 1;
      },
      // 车辆解绑
      Unbind(data){
          this.$confirm('此操作将解除绑定车辆, 是否继续?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let newdata = {
              id:data.id,
              carId:null,
              licensePlate:null
            }
            this.api.Terminal.edit(newdata)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '解绑车俩成功!'
                });
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消解绑车辆操作'
            });          
          });
      },
      // 车辆绑定
      bind(data){
        this.bindShow = true;
        this.bindData.id = data.id;
        this.api.riskCar.allData({isBindTid:false})
        .then(res=>{
          if(res.data.code == 200){
            this.bindoptions = res.data.data;
          }
        })
      },
      // 绑定车辆弹窗取消
      bindClose(){
        this.bindShow = false;
        this.bindData = {}
      },
      // 取消绑定车辆信息提交
      bindresetForm() {
        this.bindShow = false;
        this.bindData = {}
      },
      // 提交绑定数据
      bindsubmitm(formName){
        this.$refs[formName].validate((valid) => {
          if (valid) {
            let arrs = this.bindData.newcarId.split(',');
            this.bindData.carId = arrs[0];
            this.bindData.licensePlate = arrs[1];
            this.api.Terminal.edit(this.bindData)
            .then(res=>{
              if(res.data.code == 200){
                this.$message({
                  type: 'success',
                  message: '绑定车辆成功!'
                });
                this.bindData = {};
                this.tableData = [];
                this.getpurcon();
                this.current = 1;
                this.bindShow = false;
              }
            })
          }
        });
      },
    },
};
</script>
<style lang="less" scoped>
// 头部开始
.pur-top {
  width: 100%;
  height: 50px;
  padding: 9px 24px 9px 32px;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  border: 1px solid rgba(102, 102, 102, 0.15);
  .pur-size {
    font-size: 16px;
    line-height: 32px;
    color: #333;
    font-weight: 600;
  }
  .pur-right {
    .el-input {
      width: 200px;
      float: right;
    }
    .el-button {
      margin-left: 16px;
      float: right;
    }
  }
}
// 头部结束
// 内容开始
.pur-nav {
  width: calc(100% - 32px);
  height: calc(100vh - 142px);
  background-color: #fff;
  margin: 8px;
  box-shadow: 2px 2px 10px rgba(102, 102, 102, 0.15);
  padding: 8px;
  .pur-table{
    width:100%;
    height: 94%;
  } 
  // 暂无数据样式开始
  .dataAvailable{
    width: 100%;
    height: 245px;
    position: relative;
    margin-top:185px;
    margin-bottom:279px;
    .dataAva{
      position: absolute;
      top:75%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      img{
        width: 371px;
        height: 200px;
        margin: auto;
      }
      p{
        font-size: 16px;
        line-height: 24px;
        color: #333;
      }
      .el-button{
        margin-bottom:100px;
      }
    }
  }
  // 暂无数据样式结束
  .pur-pages {
    width: 100%;
    height: 6%;
    padding: 6px 0 0 12px;
    box-sizing:border-box;
    .el-pagination {
      float: right;
    }
  }
}
// 内容结束
</style>